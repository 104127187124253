// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/theo/github/badMannersLandingPage/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-data-security-js": () => import("/Users/theo/github/badMannersLandingPage/src/pages/dataSecurity.js" /* webpackChunkName: "component---src-pages-data-security-js" */),
  "component---src-pages-do-not-track-404-js": () => import("/Users/theo/github/badMannersLandingPage/src/pages/do-not-track/404.js" /* webpackChunkName: "component---src-pages-do-not-track-404-js" */),
  "component---src-pages-do-not-track-data-security-js": () => import("/Users/theo/github/badMannersLandingPage/src/pages/do-not-track/dataSecurity.js" /* webpackChunkName: "component---src-pages-do-not-track-data-security-js" */),
  "component---src-pages-do-not-track-imprint-js": () => import("/Users/theo/github/badMannersLandingPage/src/pages/do-not-track/imprint.js" /* webpackChunkName: "component---src-pages-do-not-track-imprint-js" */),
  "component---src-pages-do-not-track-index-js": () => import("/Users/theo/github/badMannersLandingPage/src/pages/do-not-track/index.js" /* webpackChunkName: "component---src-pages-do-not-track-index-js" */),
  "component---src-pages-imprint-js": () => import("/Users/theo/github/badMannersLandingPage/src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("/Users/theo/github/badMannersLandingPage/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

